.profile {
    --size: 32px;
    --border-radius: 50%;
}

.profile.profile-huge {
    --size: auto;
}

.profile ion-icon {
    font-size: 36px;
    color: var(--ion-color-light);
}

.profile.profile-huge ion-icon {
    font-size: 72px;
    color: var(--ion-color-medium);
}
