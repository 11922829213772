/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato-Regular.ttf');
}

/** Ionic CSS Variables **/
:root {
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    --ion-color-success: #00d75c;
    --ion-color-success-rgb: 0, 215, 92;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #00bd51;
    --ion-color-success-tint: #1adb6c;

    --ion-color-warning: #e7bd3a;
    --ion-color-warning-rgb: 231, 189, 58;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #cba633;
    --ion-color-warning-tint: #e9c44e;

    --ion-color-danger: #b94343;
    --ion-color-danger-rgb: 185, 67, 67;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #a33b3b;
    --ion-color-danger-tint: #c05656;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /* Custom colors for Pop-By Pro.
     * Some are built into the pre-defined colors above.
     * All are defined here for usage outside of the above.
     */
    --ion-color-logo-primary: #102b42;
    --ion-color-logo-primary-rgb: 16, 43, 66;
    --ion-color-logo-primary-contrast: #ffffff;
    --ion-color-logo-primary-contrast-rgb: 255, 255, 255;
    --ion-color-logo-primary-shade: #0e263a;
    --ion-color-logo-primary-tint: #284055;

    --ion-color-logo-secondary: #5e91b3;
    --ion-color-logo-secondary-rgb: 94, 145, 179;
    --ion-color-logo-secondary-contrast: #000000;
    --ion-color-logo-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-logo-secondary-shade: #53809e;
    --ion-color-logo-secondary-tint: #6e9cbb;

    --ion-color-logo-tertiary: #1ebbff;
    --ion-color-logo-tertiary-rgb: 30, 187, 255;
    --ion-color-logo-tertiary-contrast: var(--ion-color-logo-primary);
    --ion-color-logo-tertiary-contrast-rgb: var(--ion-color-logo-primary-rgb);
    --ion-color-logo-tertiary-shade: #1aa5e0;
    --ion-color-logo-tertiary-tint: #35c2ff;

    --color-primary-1: #102b42;
    --color-primary-2: #5e91be;

    --color-green-1: #00d75c;
    --color-green-2: #62d895;
    --color-green-3: #eefcf4;

    --color-blue-1: #539fbc;
    --color-blue-2: #1ebbff;
    --color-blue-3: #70d7ff;
    --color-blue-4: #ade8ff;

    --color-red-1: #b94343;
    --color-red-2: #e07373;
    --color-red-3: #fef0f2;

    --color-yellow-1: #c48000;
    --color-yellow-2: #e7bd3a;
    --color-yellow-3: #fffbeb;

    /* Based off of blue 1 */
    --ion-color-link: var(--color-blue-1);
    --ion-color-link-rgb: 83, 159, 188;
    --ion-color-link-contrast: #ffffff;
    --ion-color-link-contrast-rgb: 0, 0, 0;
    --ion-color-link-shade: #498ca5;
    --ion-color-link-tint: #64a9c3;

    /* Our Custom Pop-By Colors */
    --ion-color-popby-step-0: #102b42;
    --ion-color-popby-step-50: #1c364b;
    --ion-color-popby-step-100: #284055;
    --ion-color-popby-step-150: #344b5e;
    --ion-color-popby-step-200: #405568;
    --ion-color-popby-step-250: #4c6071;
    --ion-color-popby-step-300: #586b7b;
    --ion-color-popby-step-350: #647584;
    --ion-color-popby-step-400: #70808e;
    --ion-color-popby-step-450: #7c8a97;
    --ion-color-popby-step-500: #8895a1;
    --ion-color-popby-step-550: #93a0aa;
    --ion-color-popby-step-600: #9faab3;
    --ion-color-popby-step-650: #abb5bd;
    --ion-color-popby-step-700: #b7bfc6;
    --ion-color-popby-step-750: #c3cad0;
    --ion-color-popby-step-800: #cfd5d9;
    --ion-color-popby-step-850: #dbdfe3;
    --ion-color-popby-step-900: #e7eaec;
    --ion-color-popby-step-950: #f3f4f6;

    --ion-background-color: var(--ion-color-light);
    --ion-font-family: 'Lato';

    --swiper-navigation-color: var(--ion-color-logo-primary);
}

.ion-color-popby {
    --ion-color-base: var(--ion-color-popby);
    --ion-color-base-rgb: var(--ion-color-popby-rgb);
    --ion-color-contrast: var(--ion-color-popby-contrast);
    --ion-color-contrast-rgb: var(--ion-color-popby-contrast-rgb);
    --ion-color-shade: var(--ion-color-popby-shade);
    --ion-color-tint: var(--ion-color-popby-tint);
}

.ion-color-logo-primary {
    --ion-color-base: var(--ion-color-logo-primary);
    --ion-color-base-rgb: var(--ion-color-logo-primary-rgb);
    --ion-color-contrast: var(--ion-color-logo-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-logo-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-logo-primary-shade);
    --ion-color-tint: var(--ion-color-logo-primary-tint);
}

.ion-color-logo-secondary {
    --ion-color-base: var(--ion-color-logo-secondary);
    --ion-color-base-rgb: var(--ion-color-logo-secondary-rgb);
    --ion-color-contrast: var(--ion-color-logo-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-logo-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-logo-secondary-shade);
    --ion-color-tint: var(--ion-color-logo-secondary-tint);
}

.ion-color-logo-tertiary {
    --ion-color-base: var(--ion-color-logo-tertiary);
    --ion-color-base-rgb: var(--ion-color-logo-tertiary-rgb);
    --ion-color-contrast: var(--ion-color-logo-tertiary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-logo-tertiary-contrast-rgb);
    --ion-color-shade: var(--ion-color-logo-tertiary-shade);
    --ion-color-tint: var(--ion-color-logo-tertiary-tint);
}

.ion-color-link {
    --ion-color-base: var(--ion-color-link);
    --ion-color-base-rgb: var(--ion-color-link-rgb);
    --ion-color-contrast: var(--ion-color-link-contrast);
    --ion-color-contrast-rgb: var(--ion-color-link-contrast-rgb);
    --ion-color-shade: var(--ion-color-link-shade);
    --ion-color-tint: var(--ion-color-link-tint);
}

ion-toolbar {
    --ion-toolbar-background: var(--ion-color-logo-primary);
    --ion-toolbar-color: #ffffff;
    --padding-top: 8px;
    --padding-bottom: 8px;
    --padding-start: 8px;
    --padding-end: 8px;
}

ion-toggle {
    --background: var(--ion-color-light);
    --background-checked: var(--color-blue-1);
}

ion-item-divider {
    --background: var(--color-blue-4);

    --padding-top: 6px;
    --padding-bottom: 6px;
    --padding-start: 20px;
    --padding-end: 20px;
}

ion-item-divider.section-needs-attention {
    --background: var(--color-red-3);
    --color: var(--color-red-1);

    --padding-top: 6px;
    --padding-bottom: 6px;
    --padding-start: 20px;
    --padding-end: 20px;
}

ion-radio {
    --color-checked: var(--ion-color-link);
}

ion-checkbox {
    --background-checked: var(--ion-color-link);
    --border-color-checked: var(--ion-color-link);
}

.swiper-pagination-bullet {
    background-color: var(--ion-color-link) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--ion-color-logo-primary) !important;
}

.grid-fixed {
    padding: 0;
    --ion-grid-width-xs: 100%;
    --ion-grid-width-sm: 540px;
    --ion-grid-width-md: 600px;
    --ion-grid-width-lg: 600px;
    --ion-grid-width-xl: 600px;
}

ion-tab-bar,
ion-toolbar {
    padding: 0 calc((100% - 600px) / 2);
}

/* Since we support custom toggling of theme which can go against the
 * system preference, the @media block is removed and a custom class,
 * 'dark' is added.
 */
/*
* Dark Colors
* -------------------------------------------
*/
body.dark {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
}

/*
* iOS Dark Theme
* -------------------------------------------
*/
.ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
* Material Design Dark Theme
* -------------------------------------------
*/
.md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
}

.ion-flexed-input > input {
    width: auto;
}

ion-toast {
    --max-width: max-content;
}
